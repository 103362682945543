<template>
  <div class="wrapper">
    <div class="container">
      <!-- <div class="Container Flipped">
  <div class="content"> -->

      <CCard>
        <CCardBody>
          <!-- <div class="clearfix">
            <button
              type="button"
              class="btn btn-primary float-xl-right"
              @click="navToNewSymptoms"
            >
              New Job
            </button>
          </div> -->
          <div>
            <CDataTable
              :items="items"
              :fields="fields"
              striped
              column-filter
              table-filter
              :items-per-page="itemsPerPage"
              @page-change="pagechange"
              @pages-change="pageschange"
              @on="filter"
              sorter
              pagination
              ref="vuetable"
            >
              <!-- <template #firstName-filter>
              <input v-model="searchQuery" />
              <div v-for="item in filteredItem" :key="item">
                {{ item.firstName }}{{ item.lastName }}
              </div>
            </template> -->
              <template #status-filter>
                <select
                  class="form-control form-control-sm"
                  @input="
                    $refs.vuetable.columnFilterEvent(
                      'status',
                      $event.target.value,
                      'input'
                    )
                  "
                >
                  <option value selected="selected">Any</option>
                  <option value="1">Un Assigned</option>
                  <option value="2">On Going</option>
                  <option value="3">Completed</option>
                  <option value="4">Paused</option>
                </select>
              </template>
              <template #statusRange-filter>
                <select
                  class="form-control form-control-sm"
                  @input="
                    $refs.vuetable.columnFilterEvent(
                      'statusRange',
                      $event.target.value,
                      'input'
                    )
                  "
                >
                  <option value selected="selected">Any</option>
                  <option value="1">Urgent</option>
                  <option value="2">Normal</option>
                  <option value="3">Super Urgent</option>
                </select>
              </template>
              <template #index="data">
                <td>
                  {{ data.index + 1 }}
                </td>
              </template>
              <!-- <template #topicName="{item}">
        <td
          v-if="item.topicName"
        >{{ item.topicName  }}</td>
        <td v-else>--</td>
     </template> -->

              <!-- <template #topicName="{item}">
         <td v-show="item.topicName == null  ">{{ item.topic }}</td>
          <td v-show="item.topic == null ">{{ item.topicName }}</td> 
        <td class='font-weight-bold'
          v-if="item.topicId == null"
        >{{ item.topic }}</td>
        <td class='font-weight-bold' v-else>{{ item.topicName }}</td>
     </template> -->
              <!-- <template #firstName="{ item }">
              <input
                v-model="searchQuery"
                v-for="itemSearch of resultQuery"
                :key="itemSearch"
              />
             
              <td
                class="font-weight-bold"
                v-if="item.firstName && item.lastName"
              >
                {{ item.firstName }}{{ item.lastName }}
              </td>

              <td v-else>--</td>
             
            </template> -->
              <template #firstName="{ item }">
                <!-- <input v-model="searchQuery" /> -->
                <!-- <div v-for="itemSearch of filteredItem" :key="itemSearch">  -->
                <td
                  class="font-weight-bold"
                  v-if="item.firstName && item.lastName"
                >
                  {{ item.firstName }}{{ item.lastName }}
                  <!-- <input v-model="searchQuery" />
                <p v-for="item of resultQuery" :key="item">
                  {{ item.firstName }}{{ item.lastName }}
                </p> -->
                </td>
                <td v-else>--</td>
                <!-- </div> -->
              </template>
              <!-- <div v-for="item in filteredList" :key="item"> -->
              <!-- <tr v-for="item in filteredList" :key="item"> -->
              <!-- <input
                v-for="item in filterBy(items, filterInput)"
                :key="item"
                :value="filteredList"
              /> -->
              <!-- <td v-text="item | myFilter"></td> -->

              <!-- <tr v-for="item in filterList" :key="item"> -->
              <!-- <tbody v-for="itemList in filterList" :key="itemList"> -->
              <!-- <td
                class="font-weight-bold"
                v-if="item.firstName && item.lastName"
              >
                {{ item.firstName }} {{ item.lastName }}
              </td>
              <td v-else>--</td> -->
              <!-- <input
                v-for="item in filteredItem"
                :key="item"
                :value="filteredItem"
              /> -->
              <!-- <div v-for="item of resultQuery" :key="item"> -->
              <!-- <td
                class="font-weight-bold"
                v-if="item.firstName && item.lastName"
              >
                {{ item.firstName }} {{ item.lastName }}
              </td>
              <td v-else>--</td>
             
            </template> -->

              <!-- <template #authorName="{ item }">
                  <td v-if="item.author && item.author.displayName">
                    {{ item.author.displayName }}
                  </td>
                  <td v-else>--</td>
                </template> -->
              <!-- <template #lastName="{ item }">
              <td class="font-weight-bold" v-if="item.lastName">
                {{ item.lastName }}
              </td>
              <td v-else>--</td>
            </template> -->
              <template #createdTime="{ item }">
                <td class="font-weight-bold" v-if="item.createdTime">
                  {{
                    item.createdTime
                      | dateParse()
                      | dateFormat("MMM D, YYYY hh:mm A")
                  }}
                </td>
                <td v-else>--</td>
              </template>
              <template #assignedOn="{ item }">
                <td class="font-weight-bold" v-if="item.assignedOn">
                  {{
                    item.assignedOn
                      | dateParse("YYYY.MM.DD")
                      | dateFormat("MMM D, YYYY ")
                  }}
                </td>
                <td v-else>--</td>
              </template>

              <!-- <template #firstName="{ item }">
          <td v-if="item.firstName">{{ item.firstName }}</td>
          <td v-else>--</td>
        </template>
        <template #lastName="{ item }">
          <td v-if="item.lastName">{{ item.lastName }}</td>
          <td v-else>--</td>
        </template>
        <template #createdTime="{ item }">
          <td v-if="item.createdTime">  ``
            {{
              item.createdTime | dateParse() | dateFormat("MMM D, YYYY hh:mm A")
            }}
          </td>
          <td v-else>--</td>
        </template>
        <template #date="{ item }">
          <td v-if="item.date">
            {{
              item.date | dateParse("DD.MM.YYYY") | dateFormat("MMM D, YYYY ")
            }}
          </td>
          <td v-else>--</td>
        </template> -->
              <template #jobNo="{ item }">
                <td class="font-weight-bold" v-if="item.jobNo">
                  {{ item.jobNo }}
                </td>
                <td class="font-weight-bold" v-else>--</td>
              </template>
              <template #noOfTasks="{ item }">
                <td class="font-weight-bold" v-if="item.noOfTasks">
                  {{ item.noOfTasks }}
                </td>
                <td class="font-weight-bold" v-else>--</td>
              </template>
              <template #startTime="{ item }">
                <!-- <input
                :value="startDate | (dateFormat + '-' + endDate) | dateFormat"
              /> -->
                <td
                  class="font-weight-bold"
                  v-if="item.startDate && item.startTime"
                >
                  {{
                    item.startDate
                      | dateParse("YYYY.MM.DD")
                      | dateFormat("MMM D, YYYY ")
                  }}
                  {{
                    item.startTime | dateParse("HH.mm") | dateFormat("hh:mm A ")
                  }}
                </td>
                <td v-else>--</td>
              </template>
              <template #endTime="{ item }">
                <td
                  class="font-weight-bold"
                  v-if="item.endDate && item.endTime"
                >
                  {{
                    item.endDate
                      | dateParse("YYYY.MM.DD")
                      | dateFormat("MMM D, YYYY ")
                  }}
                  {{
                    item.endTime | dateParse("HH.mm") | dateFormat("hh:mm A ")
                  }}
                </td>
                <td v-else>--</td>
              </template>
              <template #status="{ item }">
                <td class="font-weight-bold" v-show="item.status == 1">
                  Un Assigned
                </td>
                <td class="font-weight-bold" v-show="item.status == 2">
                  On Going
                </td>
                <td class="font-weight-bold" v-show="item.status == 3">
                  Completed
                </td>
                <td class="font-weight-bold" v-show="item.status == 4">
                  Paused
                </td>
              </template>
              <template #statusRange="{ item }">
                <td class="font-weight-bold" v-show="item.statusRange == 1">
                  Urgent
                </td>
                <td class="font-weight-bold" v-show="item.statusRange == 2">
                  Normal
                </td>
                <td class="font-weight-bold" v-show="item.statusRange == 3">
                  Super Urgent
                </td>
                <td class="font-weight-bold" v-show="item.statusRange == 0">
                  --
                </td>
              </template>
              <template #show_details="{ item }">
                <td class="py-2">
                  <CDropdown togglerText="Actions" color="info">
                    <!-- <CDropdownItem
                :to="{
                  name: 'Tasks',
                  params: { jobId: item.jobId, mode: 'view' },
                }"
                >View
              </CDropdownItem> -->
                    <CDropdownItem
                      :to="{
                        name: 'JobAssignedUsers',
                        params: { jobId: item.jobId },
                      }"
                      >Assigned Users
                    </CDropdownItem>
                    <CDropdownItem
                      :to="{
                        name: 'JobTasks',
                        params: { jobId: item.jobId },
                      }"
                      >Job Task
                    </CDropdownItem>
                    <CDropdownItem
                      :to="{
                        name: 'JobOT',
                        params: { jobId: item.jobId },
                      }"
                      >Job OT
                    </CDropdownItem>
                    <CDropdownItem
                      v-if="item.status == 3"
                      @click="navToExportChecklistReport(item.jobId)"
                    >
                      Export</CDropdownItem
                    >
                    <ConfirmationModal
                  :parent="$refs.modalArea"
                  :description="'Are you sure to Delete'"
                  @on:ok="deleteGroup(item.jobId)"
                ></ConfirmationModal>
                    <!-- <CDropdownItem
                :to="{
                  name: 'AdvancedTables',
                  params: { jobId: item.jobId },
                }"
                >New Job
              </CDropdownItem> -->
                    <!-- <ConfirmationModal
                :parent="$refs.modalArea"
                :description="'Are you sure to Delete'"
                @on:ok="deleteFaq(item.briefingId)"
              ></ConfirmationModal> -->
                  </CDropdown>
                </td>
              </template>
              <!-- <template #showdetail="{item, index}">
        <td class="py-2">
          <CButton
            color="primary"
            variant="outline"
            square
            size="sm"
            @click="toggleDetails(item, index)"
          >
            {{Boolean(item._toggled) ? 'Hide' : 'Show'}}
          </CButton>
        </td>
      </template> -->
              <!-- <template #details="{item}">
        <CCollapse :show="Boolean(item._toggled)" :duration="collapseDuration">
          <CCardBody>
            <CMedia :aside-image-props="{ height: 102 }">
              <h4>
                {{item.firstName}}
              </h4>
              <p class="text-muted">User since: {{item.createdTime}}</p>
              <CButton size="sm" color="info" class="">
                User Settings
              </CButton>
              <CButton size="sm" color="danger" class="ml-1">
                Delete
              </CButton>
            </CMedia>
          </CCardBody>
        </CCollapse>
      </template> -->
              <!-- <template #view="{ item }">
         <td class="py-1">
         <CButton togglerText="View" color="info" size="sm">
              <CDropdownItem
                :to="{
                  name: 'Briefing',
                  params: { briefingId: item.briefingId, mode: 'view' },
                }"
                >View
              </CDropdownItem>
         </CButton>
         
         </td>
        </template> -->
            </CDataTable>
          </div>
        </CCardBody>
        <div ref="modalArea"></div>
      </CCard>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ConfirmationModal from "../../containers/ConfirmationModal";
import itemsVue from "../SWP/items.vue";
// const items = [];
const fields = [
  { key: "index", _style: "min-width:30px", _classes: "font-weight-bold" },
  //
  {
    key: "firstName",
    label: "Officer Name",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
    filterOptions: {
      // styleClass: "class1", // class to be added to the parent th element
      enabled: true, // enable filter for this column
      placeholder: "Filter This Thing", // placeholder for filter input
      filterValue: "Jane", // initial populated value for this filter
      filterDropdownItems: [], // dropdown (with selected values) instead of text input
      //custom filter function that
      trigger: "enter", //only trigger on enter not on keyup
    },
  },
  { key: "jobNo", _classes: "font-weight-bold", _style: "min-width:100px" },
  { key: "craneNo", _classes: "font-weight-bold", _style: "min-width:150px" },
  {
    key: "noOfTasks",
    label: "No Of Tasks",
    _style: "min-width:50px",
    _classes: "font-weight-bold",
  },
  {
    key: "assignedOn",
    label: "Job Assigned Date",
    _style: "min-width:180px",
    _classes: "font-weight-bold",
  },
  { key: "startTime", _classes: "font-weight-bold", _style: "min-width:180px" },
  { key: "endTime", _classes: "font-weight-bold", _style: "min-width:180px" },
  { key: "status", _classes: "font-weight-bold", _style: "min-width:150px" },
  {
    key: "checklistType",
    _classes: "font-weight-bold",
    _style: "min-width:100px",
  },
  // { key: "estimatedDuration", _classes: 'font-weight-bold', _style: "min-width:200px" },

  {
    key: "statusRange",
    label: "Range",
    _classes: "font-weight-bold",
    _style: "min-width:150px",
  },
  {
    key: "createdTime",
    label: "Created Date & Time",
    _classes: "font-weight-bold",
    _style: "min-width:180px",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
export default {
  name: "Jobs",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      paginationLastPage: 0,
      paginatedCurrentPage: 0,
      paginationNextPage: 0,
      itemsPerPage: 30,
      filterInput: "",
      searchQuery: "",
      fullName: "",
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    filter() {
      // console.log(
      //   "filter calling",
      //   this.items.filter((item) => {
      //     for (i = 0; i < this.items.length; i++) {
      //       item[i].firstName.toLowerCase() === "att" &&
      //         item[i].lastName.toLowerCase() === "eunj";
      //     }
      //   })
      // );
      var fullName = this.items.filter((item) => {
        // (item) => console.log("item values", item),
        for (i = 0; i < this.items.length; i++) {
          console.log("item values", item[i].firstName && item[i].lastName);
          return item.firstName && item.lastName;
        }
      });
      console.log("full name values", fullName);
    },
    // filterFn(data, filterString) {
    //   var x = parseInt(filterString);
    //   return data >= x - 5 && data <= x + 5;
    // },
    // computed: {
    //   fullDate() {
    //     return `${this.$options.filters.dateFormat(
    //       this.startDate
    //     )} - ${this.$options.filters.dateFormat(this.endDate)}`;
    //   },
    // },
    computed: {
      filterList() {
        value = value.charAt(0).toLowerCase() + value.slice(1);
        console.log("filetr values", value);
        return items.filter(function (item) {
          return item.firstName && item.lastName;
        });
      },
    },

    nextPage() {
      return ++this.paginationNextPage;
    },
    pagechange(activePage) {
      console.log("activePage", activePage);
      if (this.paginationLastPage - activePage < 1) {
        console.log("calling next page");
        this.getReport(this.nextPage());
      }
    },
    pageschange(totalPages) {
      console.log("totalPages", totalPages);
      this.paginationLastPage = totalPages;
    },
    getJobs() {
      const url = process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/jobs";
      this.apiGetSecure(url)
        .then((response) => response.json())
        .then((data) => (
        this.items = data)
          
        );
    },
    deleteGroup(jobId) {
      console.log("delete called with", this.jobId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/job/" +
          jobId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getJobs();
          this.$nextTick(() => {
            this.$refs.vuetable.refresh();
          });
          window.location.reload();
        });
    },
    navToExportChecklistReport(jobId) {
      var filename = "Checklist.pdf";
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/checklist/export/" +
          jobId
      )
        .then((response) => {
          if (response.status === 200) {
            console.log("headers", response.headers);
            // filename = response.headers
            //   .get("Content-Disposition")
            //   .split("filename=")[1];
            // console.log(filename);

            return response.blob();
          } else {
            return;
          }
        })
        .then((blob) => {
          var downloadUrl = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    },
    navToNewSymptoms() {
      this.$router.push({ name: "NewJob", params: { mode: "new" } });
    },
    toggleDetails(item) {
      this.$set(this.items[item.jobId], "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
  },
  computed: {
    //   this.fullName = items.filter(
    //   (item) => item.firstName  && item.lastName
    // );
    // filteredItem() {
    //   const query = this.searchQuery;
    //   console.log("search query", query);
    //   if (this.searchQuery == "") {
    //     console.log("item values from filtered", this.items);
    //     return this.items;
    //   }
    //   return this.items.filter((item) => {
    //     console.log(
    //       "item after filter",
    //       item.some((word) => string(word).toLowerCase().includes(query))
    //     );
    //     return item((word) => string(word).toLowerCase().includes(query));
    //   });
    // },
    // resultQuery() {
    //   if (this.searchQuery) {
    //     return this.items.filter((item) => {
    //       return this.searchQuery
    //         .toLowerCase()
    //         .split(" ")
    //         .every(
    //           (word) =>
    //             item.firstName.toLowerCase().includes(word) ||
    //             item.lastName.toLowerCase().includes(word)
    //         );
    //     });
    // return this.items.filter((item) => {
    //   return this.searchQuery
    //     .toLowerCase()
    //     .split(" ")
    //     .every(
    //       item.firstName.indexOf(value) > -1 &&
    //         item.lastName.indexOf(value) > -1
    //     );
    // });
  },
  mounted() {
    this.getJobs();
    // this.filter();
  },
};
</script>
<style scoped>
/* .wrapper {
   overflow: hidden; 
  display: flex; 
   flex-direction: unset;
  overflow-x: auto; 
} */

.sidebar {
  min-width: 200px;
  background: #333;
  color: #fff;
}

.container {
  flex: 1;
  overflow-x: auto;
  overflow-y: scroll;
  height: 92vh;
  /* height: 500px; */
}

.long {
  width: 2000px;
}

.header {
  background: #666;
}
.Flipped,
.Flipped .Content {
  transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
}

/* .table-responsive {
    display: block;
    width: 100%;
   
    -webkit-overflow-scrolling: touch;
} */
[class*="card"] {
  /* position: relative; */
  /* display: -webkit-box;
    display: -ms-flexbox; */
  display: flex;
  /* -webkit-box-orient: vertical;
    -webkit-box-direction: normal; */
  flex-direction: unset;
  /* min-width: 0; */
  /* margin-bottom: 1.5rem; */
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid;
  border-radius: 0.25rem;
  background-color: #fff;
  border-color: #d8dbe0;
}
</style>
<!--
@media (min-width: 768px)
<style>
.c-main > .container-fluid, .c-main > .container-sm, .c-main > .container-md, .c-main > .container-lg, .c-main > .container-xl, .c-main > .container-xxl {
    padding-right: 15px;
    padding-left: 15px;
}
</style>
-->
